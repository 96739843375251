/* .vote {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5%;
    padding: 5%;
}

.vote > img {
    width: 100%;
} */

.title {
    font-size: xx-large !important;
    font-family: cursive !important;
    color: navy !important;
}

.team {
    align-self: center;
}

.team > span {
    font-family: cursive;
}