.mainPageContainer {
    padding: 5%;
    display: grid;
}

.pickerContainer {
    display: grid;
    justify-items: center;
    justify-content: center;
    align-items: center;
    grid-template-columns: 100px 100px 50px 50px 50px 50px 50px 50px;
    grid-template-rows: 50px;
    padding: 2rem 0;
}

.HisContainer {
    display: grid;
    justify-items: center;
    justify-content: center;
    align-items: center;
    grid-template-columns: 50px 50px 50px 50px 50px 50px;
    grid-template-rows: 50px;
}

.sectionTitle {
    text-align: center;
    font-size: 47px;
    font-family: auto;
    font-style: italic;
    font-weight: 300;
    color: darkred;
}

.renderNumber {
    background-color: black;
    color: white;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
}

.renderSpecialNumber {
    background-color: red;
    color: white;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
}

.pickerContainer > img {
    padding-right: 2%;
    width: 100%;
}